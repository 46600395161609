import gsap from 'gsap';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import selfOrClosest from '../libs/self-or-closest';
import Tabs from '../tabs/tabs';
import { getTrackEvent } from '../analytics'

const gaTrackEvent = getTrackEvent({
  eventCategory: 'Main Navigation',
})

class Search {

  constructor(container) {
    this.container = container
    this.headerSearchButton = this.container.querySelector('.header__search-btn')

    this.searchContainer = document.querySelector('.header-search')
    this.closeButton = document.querySelector('.header-search__close')
    this.resultsContainer = this.container.querySelector('.header-search__results')
    // this.viewAllLinks = [...this.container.querySelectorAll('.header-search__view-all')]
    this.searchInput = this.searchContainer.querySelector('.header-search__form input[type=text]')
    this.resultTabs = [...this.searchContainer.querySelectorAll('.header-search__results-tabs button')]
    this.resultTabsContainer = this.searchContainer.querySelector('.header-search__results-tab-panels')
    this.resultTabPanels = [...this.searchContainer.querySelectorAll('[role="tabpanel"]')]
    this.closeSearchBtn = this.container.querySelector('.header-search__close-search')
    this.searchResetBtn = this.container.querySelector('.header-search__close')

    // Initialize tabs with proper configuration
    this.tabs = new Tabs(this.resultsContainer, {
      onTabChange: this.handleTabChange.bind(this)
    })
    this.tabs.init()

    this.hasResults = false;

    this.collectionConfig = {
      'products': {
        // tmpl: document.getElementById('header-search-product-card-tmpl'),
        // resultLimit: 4,
      },
      'recipes': {
        // tmpl: document.getElementById('header-search-recipe-card-tmpl'),
        // resultLimit: 2,
      },
      'pages': {
        // tmpl: document.getElementById('header-search-page-item-tmpl'),
        // resultLimit: 12,
      }
    }

    this.handlSearchInput = this.handlSearchInput.bind(this)
    this.handSearchButtonClick = this.handSearchButtonClick.bind(this)
    this.handleBodyClick = this.handleBodyClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handleSearchResetClick = this.handleSearchResetClick.bind(this)
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this)

    this.inputTimeout = null;
  }

  // New method to handle tab changes
  handleTabChange(selectedTab) {
    if (!selectedTab) return;

    const selectedCollection = selectedTab.dataset.collection;

    // Hide all panels first by removing the selected class
    this.resultTabPanels.forEach(panel => {
      panel.classList.remove('selected');
      // Also ensure consistent style reset
      // panel.style.display = 'none';
      // panel.style.visibility = 'hidden';
    });

    // Show only the selected panel by adding the selected class
    const selectedPanel = this.resultTabPanels.find(
      panel => panel.dataset.collection === selectedCollection
    );

    if (selectedPanel) {
      selectedPanel.classList.add('selected');
      // selectedPanel.style.display = 'grid';
      // selectedPanel.style.visibility = 'visible';
    }
  }

  isOpen() {
    return document.body.classList.contains('search-open')
  }

  open() {
    this.searchContainer.classList.remove('u-display-none')

    setTimeout(() => {
      document.body.classList.add('search-open')
      document.body.classList.add('search-open--results')
    })

    this.container.dispatchEvent(new CustomEvent('search_open'))
    disableBodyScroll(this.resultsContainer)

    this.searchInput.focus()
    this.ensureSearchVisibilityOnRecipePage();
  }

  close() {
    this.container.dispatchEvent(new CustomEvent('search_close'))
    document.body.classList.remove('search-open')
    document.body.classList.remove('search-open--results')
    clearAllBodyScrollLocks();
    setTimeout(() => {
      this.searchContainer.classList.add('u-display-none')
    }, 1000)
  }

  handleSearchResetClick() {
    this.loadResults('').then((response) => {
      this.searchInput.value = '';
      this.renderResults(response)
    })
  }

  handleSearchCloseClick() {
    this.close();
  }

  handSearchButtonClick(event) {
    event.preventDefault()
    this.open()
  }

  handlSearchInput(event) {
    clearTimeout(this.inputTimeout)

    // this.viewAllLinks.forEach((elem) => elem.setAttribute('href', `/search?terms=${this.searchInput.value}`))

    this.inputTimeout = setTimeout(() => {
      this.loadResults(this.searchInput.value).then((response) => {
        this.renderResults(response)
      })
    }, 250)
  }

  loadResults(searchQuery) {
    document.body.classList.add('search-open--load-results')
    return this.fetchResults(searchQuery)
  }

  fetchResults(search) {
    let searchTrimed = search.trim();

    if (searchTrimed.length === 0) {
      return Promise.resolve({
        collections: {},
        urls: [],
        total: 0,
        reset: true,
      })
    }

    return fetch('/search/results?' + (new URLSearchParams({ terms: searchTrimed })), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      gaTrackEvent({ eventAction: 'Submitted Search', eventLabel: this.searchInput.value })

      return response.json().then((json_response) => {

        console.log(json_response)

        const results = json_response.results && json_response.results || {}
        return {
          collections: results.collections || {},
          urls: json_response.searchTypeUrls,
          total: json_response.results.total,
          reset: false,
        }
      });
    })
  }

  renderResults(results) {
    document.body.classList.remove('search-open--load-results')
    // Reset the scroll position whenever new results come in
    if (this.resultTabsContainer) {
      this.resultTabsContainer.scrollTop = 0;
    }

    if (results.reset) {
      // document.body.classList.remove('search-open--results')
      document.body.classList.remove('search-open--no-results')
      document.body.classList.remove('search-open--has-results')
      document.body.classList.remove('has-searched');

      this.resultTabPanels.forEach((panel) => panel.innerHTML = '')
      return;
    }

    document.body.classList.add('has-searched');
    // document.body.classList.add('search-open--results')
    document.body.classList.toggle('search-open--no-results', results.total == 0)
    document.body.classList.toggle('search-open--has-results', results.total > 0)

    let selectedCollection;

    const existingSelectedTab = this.resultTabs.find((tab) => tab.matches('[aria-selected="true"]'))
    if (existingSelectedTab) {
      selectedCollection = existingSelectedTab.dataset.collection
    }

    // Show/Hide/Update each of the tabs
    this.resultTabs.forEach((tab) => {
      const collectionName = tab.dataset.collection
      const collection = results.collections[collectionName] || { total: 0, data: [] };
      const panel = this.resultTabPanels.find((panel) => panel.matches(`[data-collection="${collectionName}"]`))
      const hasResults = collection.total > 0;

      // Update tab visibility and count
      tab.classList.toggle('u-display-none', !hasResults);
      const countElement = tab.querySelector('[data-result-count]');
      if (countElement) {
        countElement.textContent = collection.total || 0;
      }

      if (!hasResults && selectedCollection == collectionName) {
        selectedCollection = null;
      }

      // Reset the panel content
      let panelContent = (panel && panel.matches('[data-panel-content]') && panel) ||
                         (panel && panel.querySelector('[data-panel-content]'));

      if (panelContent) {
        panelContent.innerHTML = '';
      }

      // Add results to panel
      if (collection.data && collection.data.length > 0) {
        collection.data.forEach((result) => {
          const resultNode = this.renderResult(result, collection);
          if (panelContent) {
            panelContent.appendChild(resultNode);
          }
        });
      }

      // Hide all panels initially
      if (panel) {
        panel.classList.remove('selected');
        // panel.style.display = 'none';
        // panel.style.visibility = 'hidden';
      }
    });

    // Select first visible tab if none selected
    let selectedTab;
    if (!selectedCollection) {
      selectedTab = this.resultTabs.find((tab) => !tab.classList.contains('u-display-none'));
    } else {
      selectedTab = this.resultTabs.find((tab) => tab.matches(`[data-collection="${selectedCollection}"]`));
    }

    if (selectedTab) {
      this.tabs.setSelectedTab(selectedTab);

      // Apply proper CSS classes and show the selected panel
      const collection = selectedTab.dataset.collection;

      // Add selected class to the panel and show it
      this.resultTabPanels.forEach(panel => {
        if (panel.dataset.collection === collection) {
          panel.classList.add('selected');
          // panel.style.display = 'grid';
          // panel.style.visibility = 'visible';
        } else {
          panel.classList.remove('selected');
          // panel.style.display = 'none';
          // panel.style.visibility = 'hidden';
        }
      });
    }

    this.ensureSearchVisibilityOnRecipePage();
  }

  ensureSearchVisibilityOnRecipePage() {
    // Check if we're on a recipe page
    if (document.querySelector('.recipes-listing__content') || document.body.classList.contains('recipes-listing')) {
      // When the search is open, ensure its visibility
      if (this.isOpen() && this.resultsContainer) {
        // Force reset of transform and ensure visibility
        this.resultsContainer.style.transform = 'translate3d(0, 0, 0)';
        this.resultsContainer.style.opacity = '1';
        this.resultsContainer.style.visibility = 'visible';

        // Remove any display: none class
        this.searchContainer.classList.remove('u-display-none');

        // Make sure panels container is visible
        // if (this.resultTabsContainer) {
        //   this.resultTabsContainer.style.display = 'grid';
        // }

        // Get the selected tab
        const selectedTab = this.resultTabs.find(tab => tab.getAttribute('aria-selected') === 'true');
        if (selectedTab) {
          const collection = selectedTab.dataset.collection;

          // Apply styles to show only the selected panel
          this.resultTabPanels.forEach(panel => {
            if (panel.dataset.collection === collection) {
              // Add selected class and ensure visibility
              panel.classList.add('selected');
              // panel.style.display = 'grid';
              // panel.style.visibility = 'visible';

              // Make sure grid layout is preserved
              if (collection === 'products') {
                panel.classList.add('search__results-products');
              } else if (collection === 'recipes') {
                panel.classList.add('search__results-recipes');
              }
            } else {
              // Hide non-selected panels
              panel.classList.remove('selected');
              // panel.style.display = 'none';
              // panel.style.visibility = 'hidden';
            }
          });

          // Make sure the selected tab is visually active
          // this.resultTabs.forEach(tab => {
          //   if (tab === selectedTab) {
          //     tab.style.opacity = '1';
          //   } else {
          //     tab.style.opacity = '0.7';
          //   }
          // });
        }
      }
    }
  }

  renderResult(result, collection) {
    const resultNode = document.createElement('div');
    resultNode.innerHTML = result;
    return resultNode;
  }

  handleBodyClick(event) {
    const searchContainer = selfOrClosest(event.target, '.header-search');
    const searchButton = selfOrClosest(event.target, '.header__search-btn');
    const searchResults = selfOrClosest(event.target, '.header-search__results');

    if (searchContainer || searchButton || searchResults) {
      return;
    }

    this.close();
  }

  handleKeyPress(event) {
    if (event.key.toLowerCase() == 'escape') {
      this.close();
    }
  }

  handleCloseClick() {
    this.close();
  }

  init() {
    this.headerSearchButton.addEventListener('click', this.handSearchButtonClick);
    this.searchResetBtn.addEventListener('click', this.handleSearchResetClick);
    this.closeSearchBtn.addEventListener('click', this.handleSearchCloseClick);
    this.searchInput.addEventListener('input', this.handlSearchInput);

    // Add click event listeners to tabs for manual control
    this.resultTabs.forEach(tab => {
      tab.addEventListener('click', () => {
        this.handleTabChange(tab);
      });
    });

    document.body.addEventListener('click', this.handleBodyClick);
    document.body.addEventListener('keyup', this.handleKeyPress);

    // Initial tab setup - ensure only one tab panel is visible
    const selectedTab = this.resultTabs.find(tab => tab.getAttribute('aria-selected') === 'true');
    if (selectedTab) {
      this.handleTabChange(selectedTab);
    }
  }
}

export default Search;
